import React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import styled from "@emotion/styled"

const IconImg = styled.img`
  transform: translate(${props => props.p.t.w}px, ${props => props.p.t.h}px);
`
const Circle = props => {
  return (
    <Col lg={3} sm={6} className="how-it-works--circle">
      <IconImg p={props} className="how-icon" src={props.icon} />
      <svg className="progress-ring" height="150" width="150">
        <circle
          className={`progress-ring__circle ${props.ifAnimated}`}
          strokeWidth="6"
          stroke="white"
          fill="rgba(0,0,0,0)"
          r="72"
          cx="75"
          cy="75"
        ></circle>
      </svg>

      <h4>{props.title}</h4>
      <p className="how-it-works-p">{props.message}</p>
    </Col>
  )
}

Circle.propTypes = {}

export default Circle
