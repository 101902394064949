import React, { useState, useEffect } from 'react'
import Client from 'shopify-buy'
import moment from 'moment'
import Context from '../context/StoreContext'

const client = Client.buildClient({
  storefrontAccessToken: process.env.ACCESS_TOKEN!,
  domain: `${process.env.SHOP_NAME}.myshopify.com`,
  apiVersion: '2020-07',
})

const ContextProvider = ({ children }: any) => {
  const initialStoreState = {
    client,
    adding: false,
    loadingCart: false,
    removing: false,
    localShippingSelected: false,
    localShippingValid: false,
    localPickupId:
      'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjk5ODEyMjA2MTkyOQ==',
    localPickupTime: '',
    promptDismissed: false,
    totalWeight: 0,
    checkout: { lineItems: [] },
    products: [],
    amountForSameShip: 4,
    proptSameShip: true,
    shop: {},
  }

  const [store, updateStore] = useState(initialStoreState)
  let isRemoved = false

  useEffect(() => {
    const initializeCheckout = async () => {
      updateStore(prevState => {
        return { ...prevState, loadingCart: true }
        //update cart loading again here
      })
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined'
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('shopify_checkout_id')
        : null

      const setCheckoutInState = checkout => {
        // put cart loading data in here

        if (isBrowser) {
          localStorage.setItem('shopify_checkout_id', checkout.id)
        }

        updateStore(prevState => {
          return { ...prevState, checkout, loadingCart: false }
          //update cart loading again here
        })
      }

      const createNewCheckout = () => store.client.checkout.create()
      const fetchCheckout = id => store.client.checkout.fetch(id)

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID)
          // Make sure this cart hasn’t already been purchased.
          if (!isRemoved && !checkout.completedAt) {
            setCheckoutInState(checkout)
            return
          }
        } catch (e) {
          localStorage.setItem('shopify_checkout_id', null)
        }
      }

      const newCheckout = await createNewCheckout()
      if (!isRemoved) {
        setCheckoutInState(newCheckout)
      }
    }
    initializeCheckout()
  }, [store.client.checkout])

  useEffect(() => {
    const hasLocal = sessionStorage.getItem('localPickupTime')
    const now = 4
    if (hasLocal !== null) {
      console.log('local shipping is in the session storage')
      if (hasLocal < now + 2) {
        return updateStore(prevState => {
          return {
            ...prevState,
            localShippingSelected: true,
            localShippingValid: true,
            localPickupTime: hasLocal,
          }
        })
      }
    }
    console.log('local shipping is not in the session storage')
    updateStore(prevState => {
      return {
        ...prevState,
        localShippingSelected: false,
        localShippingValid: false,
        localPickupTime: null,
      }
    })
  }, [])

  useEffect(() => {
    const updateCart = async () => {
      if (store.checkout.lineItems.length > 0) {
        let newAmountSameShip = 4
        let calcTotalWeight = 0
        if (
          store.checkout.lineItems.length === 1 &&
          store.checkout.lineItems[0].title === 'Local Pickup'
        ) {
          removeAllLocalShippingItems()
        }
        //handle amount same ship
        store.checkout.lineItems.forEach(async item => {
          // increment the total weeight for items 1 pount or less
          // the zero weight items seems to throw it off
          // sometimes the local shipping item gets added a bunch because its not handled by the checkout obj

          if (item.variant.weight >= 1) {
            calcTotalWeight =
              calcTotalWeight + item.variant.weight * item.quantity
          }
          //same ship amount only applies to the kits with weight of 1
          if (item.variant.weight === 1) {
            newAmountSameShip = newAmountSameShip - item.quantity
          }
        })
        //only have the prompt firing when you're between 1 and 3 kits
        if (store.amountForSameShip > 4 || store.amountForSameShip < 0) {
          newAmountSameShip = 0
        }
        // if there are 0 remaining products then dismiss the prompt before it goes negative

        if (!store.promptDismissed) {
          setPromptDismissed(store.amountForSameShip <= 1)
        }

        //update store with new values
        await updateStore(previousStore => {
          return {
            ...previousStore,
            amountForSameShip: newAmountSameShip,
            totalWeight: calcTotalWeight,
          }
        })
      } else {
        //reset the empty cart

        await updateStore(previousStore => {
          return {
            ...previousStore,
            totalWeight: 0,
            amountForSameShip: 4,
            localShippingSelected: false,
          }
        })
      }
    }
    updateCart()
  }, [store.checkout.lineItems, store.client.checkout])

  // this runs every time local shipping selected is set
  useEffect(() => {
    const handleCheckBox = async () => {
      if (!store.checkout.id) {
        return
      }
      // hacked up code from https://github.com/itsanolive/iao-shopify-pickup-cart-checkout/blob/master/snippets/iao-cart-shipping-form.liquid
      let input

      if (!store.localShippingSelected) {
        input = {
          customAttributes: [
            { key: 'ShipMethod', value: 'Ship to your address' },
          ],
        }
        removeAllLocalShippingItems()
      } else {
        input = {
          customAttributes: [
            { key: 'ShipMethod', value: 'Store Pickup' },
            { key: 'Local Pickup Time', value: store.localPickupTime },
          ],
        }
        addShipMethodLocal()
      }

      await client.checkout
        .updateAttributes(store.checkout.id, input)
        .then(checkout => {
          // Do something with the updated checkouts
        })
        .catch((err: any) => console.log('err: ', err))
    }
    handleCheckBox()
  }, [store.localShippingSelected])

  useEffect(
    () => () => {
      isRemoved = true
    },
    []
  )
  const removeAllLocalShippingItems = async () => {
    //if someone unchecks the box

    sessionStorage.removeItem('localPickupTime')

    //this is to handle multiple local pickup items just in case
    let checkoutItemArray = []
    store.checkout.lineItems.map(i => {
      if (i.title == 'Local Pickup') {
        checkoutItemArray.push(i)
      }
    })

    if (checkoutItemArray.length !== 0) {
      let length = checkoutItemArray.length
      // we want this to have just one if local shsipping iss selected
      // if (store.checkout.localShippingSelected) length = length - 1
      //

      let itemArray = []
      //
      for (let i = 0; i < length; i++) {
        itemArray.push(checkoutItemArray[i].id)
      }

      await client.checkout
        .removeLineItems(store.checkout.id, itemArray)
        .then(checkout => {
          updateStore(prevState => {
            return { ...prevState, checkout }
          })
        })
        .catch(err =>
          console.log('error removing items ln 193 store context', err)
        )
    }
  }

  const addShipMethodLocal = () => {
    if (store.localPickupTime === '' || !store.localPickupTime) {
      console.error('invalid local pickup time')
    } else {
      const { checkout, client } = store
      const input = {
        customAttributes: [
          { key: 'ShipMethod', value: 'Store Pickup' },
          { key: 'Local Pickup Time', value: store.localPickupTime },
        ],
      }
      client.checkout.updateAttributes(checkout.id, input).then(checkout => {
        // Do something with the updated checkout
        updateStore(prevState => {
          return { ...prevState, adding: true }
        })
        return client.checkout
          .addLineItems(checkout.id, [
            {
              variantId: store.localPickupId,
              quantity: 1,
              customAttributes: [
                { key: '__ShipMethod', value: 'Store Pickup' },
                {
                  key: '__PickupDate',
                  value: moment(store.localPickupTime).format(
                    'dddd MMMM DD hh:mma'
                  ),
                },
                {
                  key: 'Date',
                  value: moment(store.localPickupTime).format('yyyy-MM-DD'),
                },
                {
                  key: 'Time',
                  value: moment(store.localPickupTime).format('hh:mma'),
                },
              ],
            },
          ])
          .then(checkout => {
            updateStore(prevState => {
              return {
                ...prevState,
                checkout,
                localPickupTime: store.localPickupTime,
                adding: false,
              }
            })
          })
      })
    }
  }

  const setLocalPickupTime = async time => {
    sessionStorage.setItem('localPickupTime', time)
    await updateStore(prevState => {
      return {
        ...prevState,
        localPickupTime: time,
      }
    })
  }

  const setLocalShippingValid = value => {
    updateStore(prev => {
      return { ...prev, localShippingValid: value }
    })
  }
  const setPromptDismissed = value => {
    sessionStorage.setItem('promptDismissed', value)
    updateStore(prevState => {
      return { ...prevState, promptDismissed: value }
    })
  }

  return (
    <Context.Provider
      value={{
        store,
        removeAllLocalShippingItems,
        setLocalPickupTime,
        setLocalShippingValid,
        addShipMethodLocal,
        setPromptDismissed,
        setTotalWeight: value => {
          updateStore(prevState => {
            return { ...prevState, totalWeight: value }
          })
        },
        setLocalShipping: value => {
          updateStore(prevState => {
            return { ...prevState, localShippingSelected: value }
          })
        },
        changeRemainingFreeShip: num => {
          const number = store.amountForSameShip + num

          updateStore(prevState => {
            return { ...prevState, amountForSameShip: number }
          })
        },

        addClassToCart: (variantId, customAttributes, quantity = 1) => {
          updateStore(prevState => {
            return { ...prevState, adding: true }
          })
          const { checkout, client } = store

          const checkoutId = checkout.id
          return client.checkout
            .addLineItems(checkoutId, [
              {
                variantId,
                quantity: parseInt(quantity, 10),
                customAttributes: customAttributes,
              },
            ])
            .then(checkout => {
              updateStore(prevState => {
                return {
                  ...prevState,
                  checkout,
                  adding: false,
                }
              })
            })
        },
        addVariantToCart: (
          weight,
          variantId,
          quantity,
          customAttributes = []
        ) => {
          if (variantId === '' || !quantity || isNaN(weight)) {
            console.error('Both a sizes and quantity and weight are required.')
            return
          }

          updateStore(prevState => {
            return { ...prevState, adding: true }
          })

          const { checkout, client } = store

          const checkoutId = checkout.id

          const lineItemsToUpdate = [
            {
              variantId,
              quantity: parseInt(quantity, 10),
              customAttributes: customAttributes,
            },
          ]

          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(checkout => {
              updateStore(prevState => {
                let newShip = prevState.amountForSameShip
                if (parseInt(weight, 10) === 1) {
                  newShip = newShip - quantity
                }
                let newQuan = weight * quantity

                return {
                  ...prevState,
                  checkout,
                  adding: false,
                  amountForSameShip: newShip,
                }
              })
            })
        },
        removeLineItem: (client, checkoutID, lineItemID) => {
          updateStore(prevState => {
            return { ...prevState, adding: true }
          })
          return client.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res, adding: false }
              })
            })
        },
        updateLineItem: (client, checkoutID, lineItemID, quantity) => {
          const lineItemsToUpdate = [
            { id: lineItemID, quantity: parseInt(quantity, 10) },
          ]

          return client.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res }
              })
            })
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default ContextProvider
