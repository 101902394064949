import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import SingleFeaturedProduct from './SingleFeaturedProduct'
import styled from '@emotion/styled'
import { Container, Row, Col } from 'react-bootstrap'

const ProductContainer = styled(Container)`
  max-width: 1400px;
  @media all and (max-width: 765px) {
    max-width: 100%;
  }
`

const QuickAdd = styled.button`
  width: 70px;
  font-size: 24px;
`

const ProductGrid = props => {
  const { allShopifyCollection } = useStaticQuery(
    graphql`
      query {
        allShopifyCollection(filter: { title: { eq: "Featured" } }, limit: 3) {
          edges {
            node {
              title
              handle
              products {
                title
                tags
                handle
                collections {
                  handle
                }
                descriptionHtml
                id
                description
                createdAt
                featuredMedia {
                  preview {
                    ...MediaImageLocalFile
                  }
                }
                variants {
                  price
                  id
                  shopifyId
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <ProductContainer
      style={{
        marginTop: '2rem',
        marginBottom: '2rem',
      }}
    >
      <Row className="justify-content-center">
        {allShopifyCollection.edges[0].node.products.length !== 0 ? (
          allShopifyCollection.edges[0].node.products
            .filter(item =>
              item.collections.flatMap(i => i.handle).includes('kits')
            )
            .filter((p, index) => index < 3)
            .map(product => {
              return (
                <Col
                  sm={12}
                  md={6}
                  lg={4}
                  key={`product-grid--${product.title}`}
                >
                  <SingleFeaturedProduct
                    withDescription={props.withDescription}
                    showFeatured={props.showFeatured}
                    product={product}
                  />
                </Col>
              )
            })
        ) : (
          <Col sm={12} md={12} lg={12}>
            <center>
              <h3> No Featured Products </h3>
            </center>
          </Col>
        )}
        <Col md={6} lg="auto" style={{ maxWidth: '500px', marginTop: '4em' }}>
          <button
            style={{ marginTop: '2em', display: 'block' }}
            className="centered"
            onClick={() => navigate('/kits')}
          >
            Browse More
          </button>
        </Col>
      </Row>
    </ProductContainer>
  )
}

export default ProductGrid
