import InsideComponent from './InsideComponent'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
const Info = styled.div`
  position: absolute;
  top: 5%;
  left: 5%;
  max-width: 600px;
  z-index: 99;
  p {
    margin-top: 0.7rem;
    line-height: 50px;
  }
  @media all and (max-width: 1200px) {
    p {
      font-size: 1.75rem;
    }
  }
  @media all and (max-width: 900px) {
    position: relative;
    max-width: 78%;
    margin: auto;
    left: 0;
    text-align: center;
    margin-top: 2rem;
    p {
      font-size: 2rem;
      line-height: 2.25rem;
    }
  }
`
const Holder = styled.div`
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23dbdbdb' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  min-height: 120vh;
  background-position: fixed;
  width: 98%;
  margin: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;

  position: relative;
`
const WhassInside = () => {
  const data = useStaticQuery(WHATS_INSIDE)
  const { glue, instructions, box, bases, accessories } = data
  return (
    <Holder>
      <Info>
        <h2 className="blue">What's Inside</h2>
        <p className="big-p">
          Each kit includes the project base(s) of your choice, colorful glass
          accessories to create your design, glue, and step-by-step
          instructions.
        </p>
      </Info>
      <InsideComponent
        key={'whats-inside-part--box'}
        horiz={83}
        scale={1.8}
        vert={17}
        rotate={6}
        label={{ text: '', textH: '0', textV: '0' }}
        imageName={'inside-box.png'}
        src={box}
      />
      <InsideComponent
        key={'whats-inside-part--base'}
        horiz={30}
        scale={1.8}
        vert={45}
        rotate={0}
        label={{ text: 'Project Bases', textH: 38, textV: 36 }}
        imageName={'inside-bases.png'}
        src={bases}
      />

      <InsideComponent
        key={'whats-inside-part--accessories'}
        horiz={50}
        scale={1.4}
        vert={65}
        rotate={0}
        label={{ text: 'Glass Accessories', textH: 32, textV: 84 }}
        caption={'Varies based on selected kit(s)'}
        imageName={'inside-glass.png'}
        src={accessories}
      />
      <InsideComponent
        key={'whats-inside-part--instructions'}
        horiz={85}
        scale={1.7}
        vert={70}
        rotate={40}
        label={{ text: 'Step-by-Step Instructions', textH: 62, textV: 60 }}
        imageName={'inside-instructions.png'}
        src={instructions}
      />
      <InsideComponent
        key={'whats-inside-part--Glue'}
        horiz={20}
        scale={0.7}
        vert={75}
        rotate={10}
        label={{ text: 'Glue', textH: 20, textV: 76 }}
        imageName={'inside-glue.png'}
        name={'glue'}
        src={glue}
      />
    </Holder>
  )
}

export const WHATS_INSIDE = graphql`
  query whatsInsideQuery {
    box: file(relativePath: { eq: "inside-box.png" }) {
      childImageSharp {
        gatsbyImageData
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    glue: file(relativePath: { eq: "inside-glue.png" }) {
      childImageSharp {
        gatsbyImageData
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructions: file(relativePath: { eq: "inside-instructions.png" }) {
      childImageSharp {
        gatsbyImageData
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bases: file(relativePath: { eq: "inside-bases.png" }) {
      childImageSharp {
        gatsbyImageData
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    accessories: file(relativePath: { eq: "inside-glass.png" }) {
      childImageSharp {
        gatsbyImageData

        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default WhassInside
