import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link, navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faShoppingBasket,
} from '@fortawesome/free-solid-svg-icons'
import StoreContext from 'src/context/StoreContext'
import { GatsbyImage } from 'gatsby-plugin-image'
const Product = styled.div`
  background-color: white;
  min-height: 470px;
  padding: 10px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s;
  &&:hover {
    background-color: #f7f7f7;
  }
  @media all and (max-width: 768px) {
    min-height: 500px;
    min-width: 500px;
    margin: auto;
  }
  @media all and (max-width: 550px) {
    min-width: 90%;
    height: auto;
  }
`
const Styled__ProductImage = styled.div`
  background: grey;
  z-index: 50;
  object-fit: contain;
  overflow: hidden;
  height: auto;
  margin: auto;
  align-self: flex-start;
  max-height: 750px;
  position: relative;
  transition: all 0.2s;
  && img {
    width: 100%;
    height: auto;
  }
  @media all and (max-width: 768px) {
    margin: auto;
    height: 480px;
    width: 480px;
  }
  @media all and (max-width: 550px) {
    margin: auto;
    height: auto;
    width: 90%;
  }
  &&:hover > button {
    opacity: 1;
  }
  /* &&::after {
    position: absolute;
    content: ' ';
    height: 100%;
    width: 100%;

    top: 0;
    right: 0;
  }
  &&:hover::after {
    filter: blur(1);
    z-index: 51;
    background-color: rgba(255, 255, 255, 0.8);
  } */
`
const MyButton = styled.button`
  position: absolute;
  z-index: 90;
  width: 70%;
  top: 40%;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.2s;
  margin-left: auto;
  margin-right: auto;
`
const ProductMeta = styled.div`
  margin-top: 1rem;

  && h5 {
    font-family: futura-pt;
    color: var(--gr-grey) !important;
    font-size: 24px;
    font-weight: 800;
  }
  && div.description {
    font-size: 18px;
    font-family: futura-pt;
    text-align: left;
  }
  && div.price {
    font-size: 18px;
    margin-top: 1rem;
    color: black;
    font-style: italic;
    font-family: futura-pt;
    align-self: flex-end;
  }
  @media all and (max-width: 768px) {
    text-align: center;
    && h5,
    div.description,
    div.price {
      text-align: center;
    }
  }
`

const SingleFeaturedProduct = ({ withDescription, product }) => {
  const {
    addVariantToCart,
    store: { adding },
  } = useContext(StoreContext)

  const src = product.featuredMedia.preview.image.originalSrc || ''

  return (
    <Product onClick={() => navigate(`/products/${product.handle}/`)}>
      <Styled__ProductImage>
        <img src={src} alt={product.description} />
        <MyButton
          onClick={e => {
            e.stopPropagation()
            addVariantToCart(
              1,
              product.variants[0].shopifyId,
              1,
              product.tags.includes('ornament')
                ? { key: '_ornament', value: 'true' }
                : []
            )
          }}
        >
          {adding ? (
            <>
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ marginRight: '10px' }}
              ></FontAwesomeIcon>
              Adding...
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faShoppingBasket}
                style={{ marginRight: '10px' }}
              ></FontAwesomeIcon>
              Add To Cart
            </>
          )}
        </MyButton>
      </Styled__ProductImage>
      <ProductMeta>
        <h5>{product.title}</h5>

        {withDescription ? (
          <MakeDescription
            description={product.description}
            handle={product.handle}
          />
        ) : (
          <div className="description"> </div>
        )}

        <div className="price">${product.variants[0].price} </div>
      </ProductMeta>
      {/* <Button>Add To Cart</Button> */}
    </Product>
  )
}

const MakeDescription = ({ description, handle }) => {
  return (
    <div className="description">
      {description.substring(0, 130)}. . .
      <Link to={`/products/${handle}/`}>
        <button className="read-more btn-blue"> Read More</button>
      </Link>
    </div>
  )
}

export default SingleFeaturedProduct
