import React, { useEffect } from 'react'
import Section from '../components/Section'
import Hero from '../components/Hero'
import { graphql } from 'gatsby'
import WhassInside from '../components/WhatsInside'
import FeaturedProductGrid from '../components/ProductGrid'
import HowItWorks from '../components/HowItWorks'
import SEO from '../components/SEO'

type HeadProps = {
  title: string
  children?: React.ReactNode
}

export const Head = ({ title, children }: HeadProps) => (
  <>
    <title>{title}</title>
    <script>{console.log('TEST!')}</script>
    <SEO
      title="Glassroom | Make Glass, Have Fun"
      description="Glassroom is an art studio and craft site, order online, or come in for a class!"
      pagePath="/"
    />
    {children && children}
  </>
)

export default function Landing({ data }: any) {
  const [animation, fireAnimation] = React.useState(false)
  const handleAnimation = () => {
    let percentage = Math.floor(
      ((window.scrollY + window.innerHeight) / document.body.offsetHeight) * 100
    )
    if (percentage > 60) {
      fireAnimation(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleAnimation)
    return window.removeEventListener('scroll', this)
  }, [])

  return (
    <>
      <Hero
        id="home-section-1"
        headerText={'Make glass art at home!'}
        mainText={
          'Fun for all ages - glass kits are the perfect project to create with friends.'
        }
        route={'/kits'}
        buttonText={'Order a Kit!'}
        childImageSharp={data.hero.childImageSharp}
      />
      <WhassInside />

      <HowItWorks animation={animation} />
      <Section>
        <center>
          <h2 style={{ marginTop: '1em' }} className="blue">
            Featured Products
          </h2>
        </center>
        <FeaturedProductGrid withDescription={true}></FeaturedProductGrid>
      </Section>
    </>
  )
}

export const query = graphql`
  query heroQuery {
    hero: file(relativePath: { eq: "main-header2.png" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        gatsbyImageData(width: 1920)
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
