import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'

const MyComponent = styled.div<{ p: any }>`
  position: absolute;
  transform-origin: center;
  top: calc(${props => props.p.vert}% - 50px);
  left: calc(${props => props.p.horiz}% - 300px);
  transform: rotate(${props => props.p.rotate}deg)
    scale(${props => props.p.scale});
  perspective: 100px;
  height: 350px;
  width: 350px;
  transition: all 0.5s;
  background-color: rgba(0, 0, 0, 0);
  @media all and (max-width: 1500px) {
    height: 17vh;
    width: 17vw;
    top: calc(${props => props.p.vert}% - 50px);
    left: calc(${props => props.p.horiz}% - 160px);
  }
  @media all and (max-width: 900px) {
    position: relative;
    transform: rotate(0) scale(1);
    left: auto;
    margin: auto;
    height: 350px;
    width: 350px;
    transition: all 0.5s;
    &&.glue {
      height: 200px;
      width: 200px;
    }
  }
`
const Title = styled.h3<{ label: any }>`
  position: absolute;
  max-width: 250px;
  z-index:99;
  padding: 5px;
  top:calc( ${props => props.label.textV}% + 10px );
  left:calc( ${props => props.label.textH}% - 10px);
  background-color: rgba(255,255,255, 0.6);
  transition: all 0.2s;
  font-size: 2rem;
  }
  @media all and (max-width: 900px) {
    font-size: 3rem;
    position: relative;
    max-width: 350px;
    transform: rotate(0) scale(0.7) translate(0,-25px);
    margin: auto;
    left: auto;
  }
`

interface InsideComponentProps {
  horiz: any
  scale: any
  vert: any
  rotate: any
  imageName: string
  label: { text: string; textH: any; textV: any }
  src: { childImageSharp: { fluid: any } }
  name?: string
  caption?: string
}

const InsideComponent = (props: InsideComponentProps) => {
  const image = getImage(props.src.childImageSharp)
  return (
    <>
      <Title label={props.label}>{props.label.text} </Title>
      <MyComponent p={props} className={props.name || ''}>
        <GatsbyImage image={image} />
      </MyComponent>
    </>
  )
}

export default InsideComponent
