import React from 'react'
import styled from '@emotion/styled'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Image from 'src/components/Images'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const MyHero = styled.div`
  height: 75vh;
  position: relative;
  @media all and (max-width: 1400px) {
    min-height: auto;
    /* height: auto; */
  }
  @media all and (max-width: 900px) {
    min-height: 100vh;
  }
`

const BGImage = styled.div`
  position: absolute;
  z-index: 1;
  height: inherit;
  width: 100%;
  overflow: hidden;
  @media all and (max-width: 900px) {
    top: 0;
    position: relative;
    height: 50vh;
  }
`
const HeroOverlay = styled.div`
  font-family: futura-pt;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0) 70%
  );
  height: inherit;
  max-width: 98vw;
  width: 98vw;
  padding-left: 6vw;
  z-index: 2;
  position: absolute;
  display: grid;
  grid-template-columns: 45% 55%;
  && .left-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 6rem;

    div {
      margin-top: 0rem;
      margin-bottom: 2rem;
    }
    h3,
    span {
      font-size: 2rem;
      color: var(--gr-grey);
      font-family: futura-pt;
    }
    span {
      font-style: italic;
    }
  }
  @media all and (max-width: 900px) {
    /* margin-top: 500px; */

    position: relative;
    max-width: 95%;
    padding-left: 0;
    margin: auto;
    margin-top: -60px;
    grid-template-columns: 100%;
    grid-template-rows: 500px;
    text-align: center;
    background: none;
    && div {
      margin-top: 0;
      margin-bottom: 500px;
    }
    && button {
      margin-left: auto;
      margin-right: auto;
    }

    && .left-text {
      padding: 0;
      margin: 0;
      margin: auto;
    }
    && h2 {
      font-size: 3rem;
    }
    && .main-text {
      font-size: 2rem;
    }
  }
`

interface HeroProps {
  src: string
  alt: string
  headerText: string
  mainText: string
  italicsText: string
  route: string
  noOverlay?: boolean
  id?: string
  buttonText: string
  childImageSharp: any
}
const Hero = (props: HeroProps) => {
  const { id } = props || `${Math.random()}`

  const image = getImage(props.childImageSharp)
  return (
    <MyHero key={id} id={id}>
      <BGImage>
        <GatsbyImage
          image={image && image}
          src={props.src}
          alt={props.alt}
          style={{ zIndex: '0', width: '110%', objectFit: 'cover' }}
        />
      </BGImage>
      <HeroOverlay>
        <div className="left-text">
          <div>
            <h2 className="blue">{props.headerText}</h2>
          </div>
          <div>
            <p className="big-p main-text">{props.mainText} </p>
            <span>{props.italicsText}</span>
          </div>
          <AniLink
            className="splash"
            paintDrip
            direction="left"
            to={`${props.route}`}
            hex="#ea3626"
            duration={0.5}
          >
            <button>{props.buttonText}</button>
          </AniLink>
        </div>
      </HeroOverlay>
    </MyHero>
  )
}

export default Hero
