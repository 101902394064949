import React from 'react'

import Circle from './circle'
import { navigate } from 'gatsby'
import { Row, Container } from 'react-bootstrap'
import howIcon1 from '../../images/how-icon1.png'
import howIcon2 from '../../images/how-icon2.png'
import howIcon3 from '../../images/how-icon3.png'
import howIcon4 from '../../images/how-icon3.png'

import './howItWorks.css'
const HowItWorks = (props: any) => {
  const { animation } = props
  const [circleClass, setClass] = React.useState('')

  const p = offset => {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve('completed')
      }, offset)
    }).then(result => result)
  }

  return (
    <section className="section__how-it-works">
      <center>
        <h2 style={{ marginTop: '1em' }}>How It Works</h2>
      </center>

      <Container className="how-it-works__div">
        <Row>
          <Circle
            icon={howIcon1}
            t={{ h: '40', w: '20' }}
            number={1}
            title="1. Order A Kit"
            message="We can ship anywhere in the country. Your kit will ship 3-5 days from ordering."
            ifAnimated={animation ? 'completed' : ''}
          />

          <Circle
            t={{ h: '25', w: '30' }}
            icon={howIcon2}
            number={1}
            title="2. Create"
            message="When your kit arrives, read the instructions and have fun! Express your self!"
            ifAnimated={animation ? 'completed' : ''}
          />

          <Circle
            t={{ h: '25', w: '25' }}
            icon={howIcon3}
            number={1}
            title="3. Ship It Back"
            message="Once your masterpiece is complete, pack it up and send it back to us to be fired."
            ifAnimated={animation ? 'completed' : ''}
          />

          <Circle
            t={{ h: '35', w: '25' }}
            icon={howIcon4}
            number={1}
            title="4. Wait and Enjoy"
            message="We will fire your projects in our kilns and ship your finished pieces back to you."
            ifAnimated={animation ? 'completed' : ''}
          />
        </Row>

        <div className="progress-line">
          <div className="progress-line--inner"></div>
        </div>
      </Container>
      <div className="how-it-works--footing">
        <button
          onClick={() => {
            navigate('/kits/glass-kits')
          }}
        >
          Pick Your Kit
        </button>
      </div>
    </section>
  )
}

HowItWorks.propTypes = {}

export default HowItWorks
